<script setup lang="ts">

</script>

<template>
    <div class="tw-p-5">
        <RouterView />
    </div>
</template>

<style scoped lang="scss">

</style>
