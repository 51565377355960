<script setup lang="ts">
import { RouterLinkProps } from 'vue-router'

type Props = {
  text: string;
  color?: 'FINDER' | 'TRACKER' | 'BLACK' | 'GRAY-TRACKER';
  loading?: boolean;
  disabled?: boolean;
  to?: RouterLinkProps['to'];
}

const props = withDefaults(defineProps<Props>(), {
  color: 'BLACK',
  to: undefined,
})
</script>

<template>
    <RouterLink
        v-if="props.to"
        :to="to"
        class="tw-rounded-lg tw-inline-block tw-text-center tw-pt-[13px] tw-pb-[14px] tw-px-7.5 tw-text-white hover:tw-text-white tw-text-base tw-font-semibold tw-w-auto cursor-pointer"
        :class="{
            'tw-bg-tracker': props.color === 'TRACKER',
            'tw-bg-finder': props.color === 'FINDER',
            'tw-bg-black tw-text-white': props.color === 'BLACK',
            'tw-bg-gray-light-2 !tw-text-tracker': props.color === 'GRAY-TRACKER',
            'tw-opacity-50': props.loading || props.disabled
        }"
        :disabled="props.loading || props.disabled"
    >
        {{ props.text }}
    </RouterLink>
    <button
        v-else
        class="tw-rounded-lg tw-pt-[13px] tw-pb-[14px] tw-px-7.5 tw-text-white tw-text-base tw-font-semibold tw-w-full"
        :class="{
            'tw-bg-tracker': props.color === 'TRACKER',
            'tw-bg-finder': props.color === 'FINDER',
            'tw-bg-black tw-text-white': props.color === 'BLACK',
            'tw-bg-gray-light-2 !tw-text-tracker': props.color === 'GRAY-TRACKER',
            'tw-opacity-50': props.loading || props.disabled
        }"
        :disabled="props.loading || props.disabled"
    >
        {{ props.text }}
    </button>
</template>

<style scoped lang="scss">

</style>
