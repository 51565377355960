<template>
    <VLayout style="overflow: unset">
        <VNavigationDrawer
            v-model="navigation.opened"
            :mobile="breakpoint.lt('t-md')"
            :rail="breakpoint.ge('t-md')"
            :style="{
                ...(breakpoint.ge('t-md') && (navigation.hovered || navigation.submenu) ? {
                    width: '256px',
                } : {}),
            }"
            class="position-fixed tablet:!tw-hidden"
            color="primary-navigation"
            rail-width="80"
            @mouseenter="navigation.hovered = true"
            @mouseleave="navigation.hovered = false"
        >
            <template #prepend>
                <div
                    v-if="breakpoint.ge('t-md')"
                    :class="$style['logo']"
                    aria-label="PeakPicks"
                >
                    <AppLogoSymbol
                        :class="$style['logo__symbol']"
                        aria-hidden="true"
                    />

                    <VFadeTransition>
                        <AppLogoText
                            v-if="navigation.hovered || navigation.submenu || breakpoint.lt('t-md')"
                            :class="$style['logo__text']"
                            aria-hidden="true"
                        />
                    </VFadeTransition>
                </div>

                <div v-else class="px-1 py-4">
                    <VBtn
                        aria-label="Close"
                        color="white"
                        icon="mdi-close"
                        variant="text"
                        rounded="50%"
                        @click="navigation.opened = false"
                    />
                </div>
            </template>

            <VList nav>
                <VListItem
                    :to="{ name: ROUTES.BROWSE }"
                    prepend-icon="mdi-home"
                    title="Browse"
                    class="pl-5"
                    exact
                />

                <VListItem
                    :to="{ name: ROUTES.TOP_10_PICKS }"
                    prepend-icon="mdi-magnify"
                    title="Product Finder"
                    class="pl-5"
                />

                <VListItem
                    :to="{ name: ROUTES.PRODUCT_TRACKER }"
                    prepend-icon="mdi-creation"
                    title="Product Tracker"
                    class="pl-5"
                    exact
                >
                    <VChip
                        class="position-absolute"
                        color="app-orange-2"
                        size="small"
                        style="top: -10px; right: -7px;"
                        text="Free"
                        variant="flat"
                    />
                </VListItem>

                <VListItem
                    :to="{ name: ROUTES.TRENDING_PRODUCTS }"
                    prepend-icon="mdi-trending-up"
                    title="Trending Products"
                    class="pl-5"
                />
            </VList>

            <template #append>
                <VList nav>
                    <VListItem
                        :to="{ name: ROUTES.PRICING }"
                        prepend-icon="mdi-currency-usd"
                        title="Upgrade"
                        class="pl-5"
                        exact
                    />

                    <VListItem
                        :to="{ name: ROUTES.SUPPORT }"
                        prepend-icon="mdi-message-question"
                        title="Contact support"
                        class="pl-5"
                        exact
                    />
                </VList>

                <VDivider color="white" />

                <VList v-if="store.user">
                    <VMenu v-model="navigation.submenu" location="end">
                        <template #activator="{ props }">
                            <VListItem
                                :title="store.user.fullName"
                                :subtitle="store.user.email"
                                v-bind="props"
                                prepend-icon="mdi-account-box"
                                class="pl-7"
                            />
                        </template>

                        <VList class="bg-primary-navigation">
                            <VListItem
                                :to="{ name: ROUTES.SETTINGS }"
                                prepend-icon="mdi-cogs"
                                title="Settings"
                                exact
                            />

                            <VListItem
                                prepend-icon="mdi-exit-run"
                                title="Log out"
                                @click="logout"
                            />
                        </VList>
                    </VMenu>
                </VList>
            </template>
        </VNavigationDrawer>
        <div class="tw-hidden tw-w-[100px] tw-h-screen tw-fixed tw-py-5 tw-px-2.5 tablet:tw-flex tw-flex-col tw-items-center tw-bg-white">
            <img class="tw-mb-5" src="/images/small-logo.svg" alt="PeakPicks Logo" />
            <div class="tw-flex-1">
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.BROWSE }"
                >
                    <div>
                        <GridIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Browse
                    </div>
                </RouterLink>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.TOP_10_PICKS }"
                >
                    <div>
                        <ProfitFinderIcon class="tw-w-5 group-[.router-link-active]:tw-text-finder" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Product Finder
                    </div>
                </RouterLink>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.PRODUCT_TRACKER }"
                >
                    <div>
                        <ProductTrackerIcon class="group-[.router-link-active]:tw-text-tracker" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Product Tracker
                    </div>
                    <div class="tw-px-[5px] tw-py-1 tw-rounded tw-text-[#00C060] tw-font-semibold tw-text-[11px] tw-bg-white tw-leading-none">
                        FREE
                    </div>
                </RouterLink>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.TRENDING_PRODUCTS }"
                >
                    <div>
                        <TrendingIcon class="tw-w-5 group-[.router-link-active]:tw-text-tracker" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Trending Products
                    </div>
                    <div class="tw-px-[5px] tw-py-1 tw-rounded tw-text-[#00C060] tw-font-semibold tw-text-[11px] tw-bg-white tw-leading-none">
                        FREE
                    </div>
                </RouterLink>
            </div>
            <div>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.PRICING }"
                >
                    <div>
                        <RoundArrowIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Upgrade Your Plan
                    </div>
                </RouterLink>
                <hr class="tw-my-1 tw-border-black/20" />
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.SUPPORT }"
                >
                    <div>
                        <SupportIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Support
                    </div>
                </RouterLink>
                <hr class="tw-my-1 tw-border-black/20" />
                <UiDropdownMenu>
                    <UiDropdownMenuTrigger as-child>
                        <div
                            class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                        >
                            <div>
                                <ProfileIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                            </div>
                            <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                                Profile
                            </div>
                        </div>
                    </UiDropdownMenuTrigger>
                    <UiDropdownMenuContent class="tw-w-[140px]" align="end" :side-offset="10" side="right">
                        <UiDropdownMenuItem class="tw-flex tw-gap-2.5" @click="router.push({ name: ROUTES.SETTINGS})">
                            Settings
                        </UiDropdownMenuItem>
                        <UiDropdownMenuItem class="tw-flex tw-gap-2.5" @click="logout">
                            Logout
                        </UiDropdownMenuItem>
                    </UiDropdownMenuContent>
                </UiDropdownMenu>
            </div>
        </div>

        <VAppBar
            v-if="breakpoint.lt('t-md')"
            :class="[$style['top'], 'position-fixed']"
            color="primary-navigation"
            height="80"
            style="z-index: 100"
            flat
        >
            <template #prepend>
                <VAppBarNavIcon
                    color="white"
                    @click="navigation.opened = true"
                />
            </template>

            <AppLogo :class="$style['top__logo']" />
        </VAppBar>

        <VMain class="tw-flex tw-justify-center !tw-pt-20 tablet:!tw-pt-0 !tw-pl-0 tablet:!tw-pl-[100px]">
            <div class="pa-4 pa-sm-8 tw-max-w-[1400px] tw-w-full">
                <RouterView />
                <!--                <VDialog v-model="contactUsDialogOpen" width="auto" persistent>-->
                <!--                    <VCard-->
                <!--                        tag="form"-->
                <!--                        max-width="600"-->
                <!--                        min-width="480"-->
                <!--                        @submit.prevent="submit"-->
                <!--                    >-->
                <!--                        <template v-if="submitted" #title>-->
                <!--                            ✅ Success-->
                <!--                        </template>-->

                <!--                        <template v-else #title>-->
                <!--                            Did you like the report you just viewed?-->
                <!--                        </template>-->

                <!--                        <template v-if="submitted" #text>-->
                <!--                            <div class="text-high-emphasis mb-3">-->
                <!--                                Thank you for the feedback!-->
                <!--                            </div>-->

                <!--                            <VBtn-->
                <!--                                size="large"-->
                <!--                                rounded="lg"-->
                <!--                                text="Close"-->
                <!--                                exact-->
                <!--                                @click="submitted = false"-->
                <!--                            />-->
                <!--                        </template>-->

                <!--                        <template v-else #text>-->
                <!--                            <div class="mb-3">-->
                <!--                                Rate report 1-5-->
                <!--                                <VSelect-->
                <!--                                    v-model="rating.value"-->
                <!--                                    :items="rating.options"-->
                <!--                                    :error-messages="rating.errors"-->
                <!--                                    :density="breakpoint.ge('t-md') ? 'default' : 'compact'"-->
                <!--                                    :max-width="breakpoint.lt('t-sm') ? undefined : '600px'"-->
                <!--                                    class="mb-3"-->
                <!--                                    item-title="label"-->
                <!--                                    item-value="value"-->
                <!--                                    @blur="rating.validate"-->
                <!--                                />-->
                <!--                                <div v-if="rating.value != null && rating.value != 0 && rating.value < 4">-->
                <!--                                    Please tell us why-->
                <!--                                </div>-->
                <!--                                <VTextarea-->
                <!--                                    v-if="rating.value != null && rating.value != 0 && rating.value < 4"-->
                <!--                                    v-model="question.value"-->
                <!--                                    :error-messages="question.errors"-->
                <!--                                    label="Your opinion"-->
                <!--                                    max-rows="16"-->
                <!--                                    max-width="600"-->
                <!--                                    @blur="question.validate"-->
                <!--                                />-->
                <!--                            </div>-->

                <!--                            <div class="mb-8">-->
                <!--                                <VBtn-->
                <!--                                    :loading="submitting"-->
                <!--                                    type="submit"-->
                <!--                                    size="large"-->
                <!--                                    rounded="lg"-->
                <!--                                    text="Submit"-->
                <!--                                />-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                    </VCard>-->
                <!--                </VDialog>-->
            </div>
            <SupportWidget />
            <UpgradeWidget />
        </VMain>
    </VLayout>
</template>

<script lang="ts" setup>
import AppLogo from '@/assets/sprites/logo.svg'
import AppLogoSymbol from '@/assets/sprites/logo-symbol.svg'
import AppLogoText from '@/assets/sprites/logo-text.svg'

import { ServerError, UnauthorizedHttpError } from '@/api/client'

import {
  onErrorCaptured, onMounted,
  reactive, ref, watch,
} from 'vue'

import { useBreakpoint } from '@/composables/breakpoint.ts'
import useMainStore from '@/store/main'

import { ROUTES } from '@/router'
import { Field, type FieldChoice, validate } from '@/forms'
import { useShowAlert } from '@/injections/dialogs.ts'
import sendReportFeedback from '@/api/sendReportFeedback.ts'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import GridIcon from '@/kit/icons/GridIcon.vue'
import ProfitFinderIcon from '@/kit/icons/ProfitFinderIcon.vue'
import ProductTrackerIcon from '@/kit/icons/ProductTrackerIcon.vue'
import RoundArrowIcon from '@/kit/icons/RoundArrowIcon.vue'
import SupportIcon from '@/kit/icons/SupportIcon.vue'
import ProfileIcon from '@/kit/icons/ProfileIcon.vue'
import { UiDropdownMenu, UiDropdownMenuContent, UiDropdownMenuItem, UiDropdownMenuTrigger } from '@/kit/dropdown'
import TrendingIcon from '@/kit/icons/TrendingIcon.vue'
import Intercom, { shutdown } from '@intercom/messenger-js-sdk'
import SupportWidget from '@/components/SupportWidget.vue'
import UpgradeWidget from '@/components/UpgradeWidget.vue'

const breakpoint = useBreakpoint()
const navigation = reactive({
  hovered: false,
  opened: breakpoint.value.ge('t-md'),
  submenu: false,
})

const store = useMainStore()
const route = useRoute()
const router = useRouter()

const showAlert = useShowAlert()
const contactUsDialogOpen = computed(() => {
  const lastOpenedReportAtValid = store.user?.lastOpenedReportAt != null &&
    new Date(store.user.lastOpenedReportAt).getTime() > Date.now() - 1000 * 60 * 15
  const isNotReportPath = !route.path.includes('report/')

  return (lastOpenedReportAtValid && isNotReportPath) || submitted.value
})

const question = reactive({
  value: '',
  errors: [],
  validate: () => {
    switch (true) {
      case question.value.length === 0 && rating.value != null && rating.value != 0 && rating.value < 4:
        question.errors = ['This field is required']
        return
      default:
        question.errors = []
    }
  },
} as Field<string>)

watch(() => question.value, () => question.errors = [])

const submitting = ref(false)
const submitted = ref(false)

const submit = async () => {
  if (!validate([question, rating])) {
    return
  }

  try {
    submitting.value = true

    await sendReportFeedback({
      rating: rating.value!,
      message: question.value,
      reportId: Number(store.user?.lastOpenedReport),
    })
    submitted.value = true
    store.clearLastOpenedReport()
  } catch (e) {
    if (e instanceof ServerError) {
      showAlert({
        subject: 'Server error',
        message: 'Something went wrong while trying to send the request. ' +
        'But don\'t worry, we are already aware and will fix it as soon as possible!',
      })
      return
    }

    throw e
  } finally {
    submitting.value = false
  }
}

const rating = reactive({
  value: 0,
  errors: [],
  options: [
    { value: 0, label: 'Choose Value' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],
  validate: () => {
    switch (true) {
      case rating.value === null || rating.value === 0:
        rating.errors = ['This field is required']
        return
      default:
        rating.errors = []
    }
  },
} as FieldChoice<0 | null>)

const logout = () => {
  shutdown()
  store.set({
    jwt: null,
    user: null,
    isProfitFinderDemoWidgetClosed: false,
    isProductTrackerDemoWidgetClosed: false,
    isTrendingProductsDemoWidgetClosed: false,
  })
}

const connectIntercom = ({ userId, username, email, createdAt }: { userId: number | undefined; username: string | undefined; email: string | undefined; createdAt: Date | undefined }) => {
  Intercom({
    app_id: 'kjvvbbkx',
    user_id: userId?.toString(), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: Number(createdAt), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  })
}

onMounted(() => {
  connectIntercom({
    userId: store.user?.id,
    username: store.user?.fullName,
    email: store.user?.email,
    createdAt: store.user?.createdAt,
  })
})

onErrorCaptured((e) => {
  if (e instanceof UnauthorizedHttpError) {
    logout()
  }
})
</script>

<style lang="scss" module>
@use '../stylesheets/basics/colors';

.top {
  :global(.v-toolbar__content) {
    border-bottom: 1px solid rgba(var(--v-theme-on-primary-navigation), var(--v-border-opacity));
  }

  &__logo {
    width: 120px;
    height: 24px;
    color: colors.$green-2;
    margin-right: 16px;
  }
}

.logo {
  display: flex;
  width: 100%;
  padding: 24px;
  color: #DFFF72;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  &__symbol {
    display: inline-block;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  &__text {
    display: inline-block;
    height: 24px;
    flex-shrink: 0;
  }
}
</style>
