<template>
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19 18.5H2V1.5C2 0.95 1.55 0.5 1 0.5C0.45 0.5 0 0.95 0 1.5V19.5C0 20.05 0.45 20.5 1 20.5H19C19.55 20.5 20 20.05 20 19.5C20 18.95 19.55 18.5 19 18.5Z" fill="currentColor" />
        <path d="M5.71031 14.21L8.00031 11.92L10.2903 14.21C10.4903 14.41 10.7403 14.5 11.0003 14.5C11.2603 14.5 11.5103 14.4 11.7103 14.21L17.0003 8.92001V10.51C17.0003 11.06 17.4503 11.51 18.0003 11.51C18.5503 11.51 19.0003 11.06 19.0003 10.51V6.51001C19.0003 6.38001 18.9703 6.25001 18.9203 6.13001C18.8203 5.89001 18.6203 5.69001 18.3803 5.59001C18.2603 5.54001 18.1303 5.51001 18.0003 5.51001H14.0003C13.4503 5.51001 13.0003 5.96001 13.0003 6.51001C13.0003 7.06001 13.4503 7.51001 14.0003 7.51001H15.5903L11.0003 12.1L8.71031 9.81001C8.32031 9.42001 7.69031 9.42001 7.30031 9.81001L4.30031 12.81C3.91031 13.2 3.91031 13.83 4.30031 14.22C4.69031 14.61 5.32031 14.61 5.71031 14.22V14.21Z" fill="currentColor" />
    </svg>
</template>
<script setup lang="ts">
</script>
