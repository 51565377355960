<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from '@/router.ts'
import { computed, onMounted, ref, watch } from 'vue'
import useMainStore from '@/store/main.ts'

const route = useRoute()
const router = useRouter()
const store = useMainStore()

const isOpen = ref(true)

const isDisplayed = computed(() => {
  const showProfitFinder = route.name === ROUTES.TOP_10_PICKS && !store.isProfitFinderDemoWidgetClosed
  const showProductTracker = route.name === ROUTES.PRODUCT_TRACKER && !store.isProductTrackerDemoWidgetClosed
  const showTrendingProducts = route.name === ROUTES.TRENDING_PRODUCTS && !store.isTrendingProductsDemoWidgetClosed
  return showProfitFinder || showProductTracker || showTrendingProducts
})

const closeWidget = () => {
  isOpen.value = false
  if (route.name === ROUTES.TOP_10_PICKS) store.set({ isProfitFinderDemoWidgetClosed: true })
  if (route.name === ROUTES.PRODUCT_TRACKER) store.set({ isProductTrackerDemoWidgetClosed: true })
  if (route.name === ROUTES.TRENDING_PRODUCTS) store.set({ isTrendingProductsDemoWidgetClosed: true })
}

watch(() => route.name, () => {
  isOpen.value = true
})

// watch(() => route.name, (value, oldValue) => {
//   const previousRoutes = [ROUTES.REPORT_PRODUCT, ROUTES.REPORT_PRODUCT_PUBLIC, ROUTES.REPORT, ROUTES.REPORT_PUBLIC]
//   if (value === ROUTES.BROWSE && previousRoutes.includes(oldValue)) {
//     isDisplayedOnBrowsePage.value = true
//   } else {
//     isDisplayedOnBrowsePage.value = false
//   }
// })

</script>

<template>
    <transition>
        <div v-if="isDisplayed && isOpen" class="tw-fixed tw-bottom-[100px] tw-right-5 tw-max-w-[500px] tw-rounded-2xl tw-p-0 tw-bg-transparent tw-shadow-dropdown">
            <div class="tw-overflow-hidden tw-rounded-2xl tw-bg-transparent">
                <video v-if="route.name === ROUTES.TOP_10_PICKS" src="/videos/Demo_Dropship_sourcing_1.1.mp4" controls />
                <video v-else-if="route.name === ROUTES.PRODUCT_TRACKER" src="/videos/Demo_Product_Tracker_v2.0.mp4" controls />
                <video v-else-if="route.name === ROUTES.TRENDING_PRODUCTS" src="/videos/Demo_Trending_Products_v2.0.mp4" controls />
            </div>
            <button class="tw-absolute -tw-top-4 -tw-right-2 tw-px-3 tw-py-1 tw-bg-finder tw-rounded-full tw-text-white tw-font-medium" @click="closeWidget">
                Close
            </button>
        </div>
    </transition>
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
