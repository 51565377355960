import type { JWT } from '@/api/client'
import type { User } from '@/domain/user'

import { defineStore } from 'pinia'

export type State = {
  jwt: JWT | null;
  user: User | null;
  isProfitFinderDemoWidgetClosed: boolean;
  isProductTrackerDemoWidgetClosed: boolean;
  isTrendingProductsDemoWidgetClosed: boolean;
}

export default defineStore({
  id: 'main',

  state: (): State => ({
    jwt: null,
    user: null,
    isProfitFinderDemoWidgetClosed: false,
    isProductTrackerDemoWidgetClosed: false,
    isTrendingProductsDemoWidgetClosed: false,
  }),

  getters: {
    authenticated: state => state.jwt !== null,
  },

  actions: {
    set ({ jwt, user, isProfitFinderDemoWidgetClosed, isProductTrackerDemoWidgetClosed, isTrendingProductsDemoWidgetClosed }: Partial<State>) {
      if (typeof jwt !== 'undefined') { this.jwt = jwt }
      if (typeof user !== 'undefined') { this.user = user }
      if (typeof isProfitFinderDemoWidgetClosed !== 'undefined') { this.isProfitFinderDemoWidgetClosed = isProfitFinderDemoWidgetClosed }
      if (typeof isProductTrackerDemoWidgetClosed !== 'undefined') { this.isProductTrackerDemoWidgetClosed = isProductTrackerDemoWidgetClosed }
      if (typeof isTrendingProductsDemoWidgetClosed !== 'undefined') { this.isTrendingProductsDemoWidgetClosed = isTrendingProductsDemoWidgetClosed }
    },

    unset () {
      this.jwt = null
      this.user = null
      this.isProfitFinderDemoWidgetClosed = false
      this.isProductTrackerDemoWidgetClosed = false
      this.isTrendingProductsDemoWidgetClosed = false
    },

    disableTrial () {
      if (this.user) {
        this.user.trialsAvailable = 0
      }
    },

    setLastOpenedReport (id: number) {
      if (this.user) {
        this.user.lastOpenedReport = id
        this.user.lastOpenedReportAt = new Date()
      }
    },

    clearLastOpenedReport () {
      if (this.user) {
        this.user.lastOpenedReport = null
        this.user.lastOpenedReportAt = null
      }
    },
  },
})
