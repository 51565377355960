<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from '@/router.ts'
import { computed, onMounted, ref, watch } from 'vue'
import { UiButton } from '@/kit'

const route = useRoute()
const router = useRouter()

const isDisplayed = ref(false)

// const isDisplayed = computed(() => {
//   return route.name === ROUTES.TOP_10_PICKS || route.name === ROUTES.PRODUCT_TRACKER || route.name === ROUTES.TRENDING_PRODUCTS
// })

watch(() => route.name, (value, oldValue) => {
  const previousRoutes = [ROUTES.REPORT_PRODUCT, ROUTES.REPORT_PRODUCT_PUBLIC, ROUTES.REPORT, ROUTES.REPORT_PUBLIC]
  // @ts-ignore
  if (value === ROUTES.BROWSE && previousRoutes.includes(oldValue)) {
    isDisplayed.value = true
  } else {
    isDisplayed.value = false
  }
})

</script>

<template>
    <transition>
        <div v-if="isDisplayed" class="tw-card tw-bg-finder tw-fixed tw-bottom-[100px] tw-right-5 tw-max-w-[500px] tw-w-full tw-shadow-dropdown desktop:tw-p-7.5">
            <div class="tw-text-white tw-grid tw-grid-cols-[48px,1fr] tw-gap-5">
                <div />
                <div>
                    <div class="tw-inline-block tw-px-2.5 tw-bg-white tw-rounded tw-uppercase tw-tracking-wide tw-text-green tw-text-[11px] tw-font-semibold">
                        No Pay, Cancel Anytime
                    </div>
                </div>
                <div class="tw-flex tw-justify-center tw-items-center">
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="48" height="48" rx="24" fill="#0165F3" />
                        <circle
                            cx="24"
                            cy="24"
                            r="18"
                            stroke="white"
                            stroke-width="2"
                        />
                        <path
                            d="M18 22L24 16L30 22"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <rect
                            x="23"
                            y="16"
                            width="2"
                            height="18"
                            rx="1"
                            fill="white"
                        />
                    </svg>
                </div>
                <div class="tw-text-h3">
                    Unlock unlimited reports, access more products for analysis, and speed up your data processing
                </div>
                <div />
                <div>
                    <UiButton class="!tw-w-auto" text="Try for free" @click="router.push({name: ROUTES.PRICING})" />
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
